import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Anna = () => {
    return (
        <Layout>
            <Seo title="Anna Rostek"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Anna Rostek</h1>
                        <p className="member-intro_text_description">Certyfikowana instruktorka Pilatesu oraz Holistic Wellness Coach
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_anna.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="instruktorka Anna Rostek" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O ANNIE</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Certyfikowana instruktorka Pilatesu oraz Holistic Wellness Coach.
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Anna pracuje metodą Pilates w nurcie współczesnym, czyli sportowo – rehabilitacyjnym.</p>
                            <p className="member-hero-paragraph_info-text">Jej życie od zawsze przepełnione było ruchem i sportem. Od najmłodszych lat przeróżne formy aktywności współtworzą życie Anny: narciarstwo, snowboard, windsurfing, kitesurfing, wspinaczka, pływanie, longboard, yoga, taniec. Przy tak licznych aktywnościach, to właśnie Pilates skradł serce Anny i obecnie tą formą ruchu chce zarażać innych i wspierać w budowaniu sprawności, dzięki której można cieszyć się pełnią życia.</p>
                            <p className="member-hero-paragraph_info-text">W metodzie Pilates najbardziej ceni sobie jego wszechstronność, która przynosi liczne korzyści nie tylko dla ciała, ale też i umysłu - neuroplastyczność mózgu. 
                            W dzisiejszym zabieganym świecie, z wieloma dystraktorami zapominamy o poświęcaniu uwagi odczuciom i informacjom płynących z ciała. Zapominamy o wsłuchiwaniu się w to, co do nas mówi. Za pomocą metody Pilates Anna pracuje z klientami i klientkami nie tylko nad sprawnością i funkcjonalnością ciała, ale też nad budowaniem jego pogłębionej świadomości. Większa świadomość jest kluczowym elementem do głębszego zrozumienia siebie i osiągnięcia harmonii i dobrostanu. 
                            </p>
                            <p className="member-hero-paragraph_info-text">Poza salą treningową Anna udziela również konsultacji jako Holistic Wellness Coach, czyli konsultacji w zakresie zdrowego stylu życia. Dogłębnie analizuje obecny stan zdrowia klientek i klientów oraz wspiera w procesie zmiany ich nawyków i stylu życia. Podróż Anny – bogata w różne doświadczenia – do miejsca, w którym znajduje się obecnie, zajęła jej kilkanaście lat i może teraz śmiało pomóc klientom i klientkom zrozumieć złożoność czynników wpływających na pełen dobrostan w zakresie ich zdrowia.</p>
                            <p className="member-hero-paragraph_info-text">Stale rozwija się w różnych formach pracy z ciałem, nie tylko w zakresie metody Pilates, ale również w obszarze terapii tańcem i ruchem, technik uwalniania emocji, czy movement medicine. Jednocześnie wciąż pogłębia wiedzę z obszaru holistycznego podejścia do zdrowia, naturoterapii, dietetyki, czy mindfulness.</p>
                            <p className="member-hero-paragraph_info-text">Interesuje się neuroplastycznością, psychosomatyką, ziołolecznictwem, medycyną chińską i ajurwedyjską.</p>
                            <p>W wolnym czasie tańczy, fotografuje i podróżuje.</p>
                            <p>Wcześniej przez wiele lat była związana ze światem managerskim w amerykańskiej korporacji, gdzie odpowiadała za wprowadzanie produktów światowej marki na rynek europejski. Zarządzała implementacją nowych procesów oraz automatyzacją już istniejących. Tworząc nowe narzędzia pracy dla całego zespołu przeprowadziła niezliczone godziny szkoleń.</p>
                            <p>Ukończyła studia magisterskie na kierunku Zarządzania na Uniwersytecie Warszawskim, publikując w tym czasie trzy artykuły naukowe.</p>    
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Pilates</li>
                            <li>Trening oddechowy</li>
                            <li>Trening psychorelaksacyjny</li>
                            <li>Trening neuroplastyczności</li>
                            <li>Konsultacje z zakresu zdrowego stylu życia jako Holistic Wellness Coach</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Zajęcia indywidualne</li>
                                <li>Zajęcia w duetach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Reformer</li>
                            <li>Reformer z Wieżą</li>
                            <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Osoby z wadami postawy</li>
                            <li>Osoby z urazami i dolegliwościami bólowymi</li>
                            <li>Sportowcy</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_info_link-container">
                            <Link to="/zapisy" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</Link>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Anna;